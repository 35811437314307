import { ApolloLink } from '@apollo/client';
import { mainClient } from 'apollo/client';
import { createAuthenticationLink, errorLink, mainLink } from 'apollo/link';
import { AuthenticationModel } from 'components/providers/authentication-provider';
import { DEFAULT_DESCRIPTION, DEFAULT_OG_IMAGE, DEFAULT_TITLE, MAIN_DOMAIN } from 'globalConstants';
import {
  ApprovalStatusEnumType,
  GET_REAL_ESTATES,
  GetRealEstatesData,
  GetRealEstatesVariables,
  ListRealEstate,
  PostStatusType,
  TradingStatus,
} from 'graphql/main/queries/get-real-estates';
import {
  B2CUser,
  GET_REPRESENTATIVE_BROKERS,
  GetRepresentativeBrokersData,
  GetRepresentativeBrokersVariables,
} from 'graphql/main/queries/get-representative-brokers';
import {
  CompanyEnum,
  LIST_COMPANIES_BY_TYPE,
  ListCompaniesByTypeData,
  ListCompaniesByTypeVariables,
  ListCompany,
} from 'graphql/main/queries/list-companies-by-type';
import { TypeOfDemandEnumType } from 'graphql/map/queries/get-suggestions';
import { isEqual, toString } from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Context, Fragment, createContext } from 'react';
import { detectDeviceByUserAgent } from 'utils';

const Home = dynamic(() => import('components/desktop/home'));
const MobileHome = dynamic(() => import('components/mobile/home'));

const DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM = 12;
const DEFAULT_LIMIT_ENTERPRISE_CARD_ITEM = 21;
const DEFAULT_LIMIT_BROKER_CARD_ITEM = 12;
const HO_CHI_MINH_PROVINCE_ID = '642a83c4176b5d214073f27c';
const HA_NOI_PROVINCE_ID = '642a83c4176b5d214073f24b';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  const { headers, cookies } = req;
  const authenticationJSONString = toString(cookies['authentication']);
  if (authenticationJSONString) {
    const { accessToken } = JSON.parse(authenticationJSONString) as AuthenticationModel;
    if (accessToken) {
      const authLink = createAuthenticationLink(accessToken);
      mainClient.setLink(ApolloLink.from([errorLink, authLink, mainLink]));
    }
  }
  const device = detectDeviceByUserAgent(headers['user-agent']);
  let pageData = null;
  const [
    { data: getRealEstates1Data },
    { data: getRealEstates2Data },
    { data: getRealEstates3Data },
    { data: getRealEstates4Data },
    { data: getRealEstates5Data },
    { data: getRealEstates6Data },
    { data: getRealEstates7Data },
    { data: listCompaniesByTypeData },
    { data: getRepresentativeBrokersData },
  ] = await Promise.all([
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM,
        isAuthorizedRe: true,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: 0,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        typeOfDemand: TypeOfDemandEnumType.ForSale,
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        province: HO_CHI_MINH_PROVINCE_ID,
        typeOfDemand: TypeOfDemandEnumType.ForSale,
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        province: HA_NOI_PROVINCE_ID,
        typeOfDemand: TypeOfDemandEnumType.ForSale,
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: 0,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        typeOfDemand: TypeOfDemandEnumType.ForRent,
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        province: HO_CHI_MINH_PROVINCE_ID,
        typeOfDemand: TypeOfDemandEnumType.ForRent,
      },
    }),
    mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
      query: GET_REAL_ESTATES,
      variables: {
        limit: DEFAULT_LIMIT_REAL_ESTATE_CARD_ITEM,
        approvalStatus: ApprovalStatusEnumType.approved,
        postStatus: [PostStatusType.active],
        tradingStatus: [TradingStatus.trading],
        province: HA_NOI_PROVINCE_ID,
        typeOfDemand: TypeOfDemandEnumType.ForRent,
      },
    }),
    mainClient.query<ListCompaniesByTypeData, ListCompaniesByTypeVariables>({
      query: LIST_COMPANIES_BY_TYPE,
      variables: {
        limit: DEFAULT_LIMIT_ENTERPRISE_CARD_ITEM,
        isPromoted: true,
        type: CompanyEnum.estate,
      },
    }),
    mainClient.query<GetRepresentativeBrokersData, GetRepresentativeBrokersVariables>({
      query: GET_REPRESENTATIVE_BROKERS,
      variables: {
        limit: DEFAULT_LIMIT_BROKER_CARD_ITEM,
      },
    }),
  ]);
  const { getRealEstates: depositRealEstatesWithPagination } = getRealEstates1Data;
  const { getRealEstates: saleRealEstatesWithPagination } = getRealEstates2Data;
  const { getRealEstates: saleInHoChiMinhRealEstatesWithPagination } = getRealEstates3Data;
  const { getRealEstates: saleInHaNoiRealEstatesWithPagination } = getRealEstates4Data;
  const { getRealEstates: rentRealEstatesWithPagination } = getRealEstates5Data;
  const { getRealEstates: rentInHoChiMinhRealEstatesWithPagination } = getRealEstates6Data;
  const { getRealEstates: rentInHaNoiRealEstatesWithPagination } = getRealEstates7Data;
  const { listCompaniesByType: companiesWithPagination } = listCompaniesByTypeData;
  const { getRepresentativeBrokers: representativeBrokers } = getRepresentativeBrokersData;

  pageData = {
    depositRealEstatesWithPagination: depositRealEstatesWithPagination ?? null,
    saleRealEstatesWithPagination: saleRealEstatesWithPagination ?? null,
    saleInHoChiMinhRealEstatesWithPagination: saleInHoChiMinhRealEstatesWithPagination ?? null,
    saleInHaNoiRealEstatesWithPagination: saleInHaNoiRealEstatesWithPagination ?? null,
    rentRealEstatesWithPagination: rentRealEstatesWithPagination ?? null,
    rentInHoChiMinhRealEstatesWithPagination: rentInHoChiMinhRealEstatesWithPagination ?? null,
    rentInHaNoiRealEstatesWithPagination: rentInHaNoiRealEstatesWithPagination ?? null,
    companiesWithPagination: companiesWithPagination ?? null,
    representativeBrokers: representativeBrokers ?? null,
  };

  return {
    props: {
      device,
      pageData,
    },
  };
};

export const HomePageContext: Context<Omit<Props, 'device'>> = createContext({});

interface Props {
  device?: 'mobile' | 'desktop';
  pageData?: {
    depositRealEstatesWithPagination?: ListRealEstate | null;
    saleRealEstatesWithPagination?: ListRealEstate | null;
    saleInHoChiMinhRealEstatesWithPagination?: ListRealEstate | null;
    saleInHaNoiRealEstatesWithPagination?: ListRealEstate | null;
    rentRealEstatesWithPagination?: ListRealEstate | null;
    rentInHoChiMinhRealEstatesWithPagination?: ListRealEstate | null;
    rentInHaNoiRealEstatesWithPagination?: ListRealEstate | null;
    companiesWithPagination?: ListCompany | null;
    representativeBrokers?: B2CUser[] | null;
  } | null;
}

const HomePage: NextPage = (props: Props) => {
  const { device, ...restProps } = props;
  const title = DEFAULT_TITLE;
  const description = DEFAULT_DESCRIPTION;
  const ogUrl = MAIN_DOMAIN;
  const ogImage = DEFAULT_OG_IMAGE;

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        <meta name='description' content={description} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:url' content={ogUrl} />
        <meta name='og:image' content={ogImage} />
        <title>{title}</title>
      </Head>
      <HomePageContext.Provider value={restProps}>
        {isEqual(device, 'mobile') ? <MobileHome /> : isEqual(device, 'desktop') ? <Home /> : null}
      </HomePageContext.Provider>
    </Fragment>
  );
};

export default HomePage;
